<add-edit-user-modal (onSubmit)="getUsers()" (onHide)="getUsers()" [accounts]="accounts" [roles]="roles" [officeAddresses]="userModalDetails.officeAddresses" [userId]="userModalDetails.userId"></add-edit-user-modal>
<div class="panel panel-sky">
  <div class="panel-heading">
    <h4><i class="fa fa-users"></i> Users Management</h4>
  </div>
  <div class="panel-body collapse in" style="display: block;">
    <div id="example_wrapper" class="dataTables_wrapper" role="grid">
      <div class="row" style="padding-bottom: 10px;">
        <div class="col-xs-12">
          <button class="btn btn-secondary btn-label" (click)="addNewUser()"><i class="fa fa-user-circle"></i> New User</button>
          <button class="btn btn-secondary btn-label" (click)="bulkUserUpload()"><i class="fa fa-users"></i> Bulk User Registration</button>
        </div>
      </div>
      
      <div *ngIf="!viewReady && !viewFailed">
        <i class="loader fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      </div>
      <div *ngIf="viewFailed" class="text-danger">
        Failed to retrieve users. <a class="text-clickable" (click)="getUsers()">Retry</a>
      </div>
      <div *ngIf="viewReady">
        <div class="row">
          <div class="col-xs-3">
            <div class="dataTables_filter">
              <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="query">
            </div>
          </div>
          <div class="col-xs-6 col-xs-offset-3">
            <div id="example_length" class="dataTables_length pull-right">
              <label>
                <select size="1" class="form-control" (change)="listSize = $event.target.value">
                  <option *ngFor="let listSizeOption of recordsPerPageOptions" [attr.value]="listSizeOption" [selected]="listSizeOption === listSize">{{listSizeOption}}</option>
                </select>
              </label>
              records per page
            </div>
          </div>
        </div>
        <table cellpadding="0" cellspacing="0" border="0" class="table table-striped table-bordered table-hover datatables dataTable">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>IsActive</th>
              <th>Account</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr class="gradeA" *ngFor="let i = index; let user of users | search:query | paginate: { itemsPerPage: listSize, currentPage: currentPage }" [class.odd]="i % 2 !== 0" [class.even]="i % 2 === 0">
              <td><a class="text-clickable" (click)="viewUser(user.userId)">{{user.firstName}} {{user.lastName}}</a></td>
              <td>{{user.email}}</td>
              <td>
                <span class="badge" [class.badge-success]="user.isActive" [class.badge-danger]="!user.isActive">
                  {{user.isActive ? 'Active' : 'Disabled'}}
                </span>  
              </td>
              <td>
                <div *ngIf="!!user.account" class="name text-clickable" (click)="viewAccount(user.account)">{{user.account.name}}<i class="fa fa-eye" aria-hidden="true"></i></div>
              </td>
              <td>
                <span dropdown class="btn-group">
                  <button dropdownToggle class="btn btn-primary btn-label"><i class="fa fa-ellipsis-v" aria-hidden="true"></i> More </button>
                  <ul *dropdownMenu class="dropdown-menu user-actions-menu">
                      <li>
                          <a class="text-clickable" (click)="unsubscribeAutoUpdates(user)">
                            <i class="fa fa-envelope-o"></i> Unsubscribe AU
                          </a>
                        </li>
                    <li>
                      <a class="text-clickable" (click)="openResetPassword(user)">
                        <i class="fa fa-key"></i> Reset Password
                      </a>
                    </li>

                    <li>
                      <a class="text-clickable" (click)="openResetDisclaimer(user)">
                        <i class="fa fa-book" aria-hidden="true"></i> Reset Disclaimer
                      </a>
                    </li>
                    <li>
                      <a *ngIf="user.isActive" class="text-clickable" (click)="changeUserActivationStatus(user, false)">
                        <i class="fa fa-times-circle-o text-danger" aria-hidden="true"></i> Deactivate User
                      </a>
                      <a *ngIf="!user.isActive" class="text-clickable" (click)="changeUserActivationStatus(user, true)">
                        <i class="fa fa-check-circle-o text-success" aria-hidden="true"></i> Activate User
                      </a>
                    </li>
                  </ul>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-xs-6 dataTables_info">Showing {{p.service.instances.DEFAULT_PAGINATION_ID.totalItems}} users</div>
          <pagination-template #p="paginationApi"
                (pageChange)="currentPage = $event" class="col-xs-6">
            <div class="dataTables_paginate paging_bootstrap">
              <ol class="pagination">
                <li class="prev" [class.disabled]="p.isFirstPage()">
                  <a class="text-clickable" (click)="p.previous()">
                  <i class="fa fa-long-arrow-left"></i> Previous</a>
                </li>
                
                <li *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                    <a class="text-clickable" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                        <span>{{ page.label }}</span>
                    </a>
                    <a *ngIf="p.getCurrent() === page.value"><span>{{ page.label }}</span></a>
                </li>

                <li class="next" [class.disabled]="p.isLastPage()">
                  <a class="text-clickable" (click)="p.next()">Next <i class="fa fa-long-arrow-right"></i></a>
                </li>
              </ol>
            </div>
          </pagination-template>
        </div>
      </div>
    </div>
  </div>
</div>
<app-reset-password-modal *ngIf="!!resetPasswordUser" (onClose)="onResetPasswordModalClosed()"
  [user]="resetPasswordUser">
</app-reset-password-modal>
<app-reset-disclaimer-modal *ngIf="!!resetDisclaimerUser" (onClose)="onResetDisclaimerModalClosed()"
  [user]="resetDisclaimerUser">
</app-reset-disclaimer-modal>
