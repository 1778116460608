<div class="autocomplete-container" [class.open]="displayDropdown" (blur)="onBlurEvent()">
  <i class="fa fa-chevron-down" aria-hidden="true" (click)="toggleDropdown()"></i>
  <input class="form-control" [ngModel]="displayValue" (ngModelChange)="changeValue($event)" 
        #inputEl (keyup.enter)="selectOption()" (keydown)="keyDown($event)" />
  <ul class="dropdown-menu">
    <li *ngFor="let result of results; let i = index" [class.active]="activeResultIndex === i">
      <a class="text-clickable" (click)="selectOption(result)">
        {{result.value}}
      </a>
    </li>
  </ul>
</div>
