<div class="select-account">
  <div class="form-box">
    <div class="form-holder">
      <h3 class="form-title">View lead</h3>
      <div class="form-body">
        <div>
          <span class="col-md-12">
            <label>Email:</label>
            <span>{{editedLead.email}}</span>
          </span>
        </div>
        <div>
          <span class="col-md-6">
            <label>Status:</label>
            <span>{{LEAD_STATUS_MAP[editedLead.status]}}</span>
          </span>
          <span class="col-md-6">
            <label>Source:</label>
            <span>{{editedLead.source}}</span>
          </span>
        </div>
        <div>
          <span class="col-md-12 account">
            <label>Account:</label>
            <select class="form-control" [disabled]="!isEditMode" [(ngModel)]="editedLead.account.id">
              <option *ngFor="let account of accounts" [value]="account.id">
                {{account.name}}
              </option>
            </select>
          </span>
        </div>
        <div>
          <span class="col-md-6">
            <label>Approved Date:</label>
            <span>{{editedLead.approvedDate | date}}</span>
          </span>
          <span class="col-md-6">
            <label>Email Verified Date:</label>
            <span>{{editedLead.emailVerifiedDate | date}}</span>
          </span>
        </div>
        <div>
          <span class="col-md-6">
            <label>Created At Date:</label>
            <span>{{editedLead.createdAt | date}}</span>
          </span>
          <span class="col-md-6">
            <label>Updated At Date:</label>
            <span>{{editedLead.updatedAt | date}}</span>
          </span>
        </div>
        <div>
          <span class="col-md-6">
            <label>Registered Date:</label>
            <span>{{editedLead.registeredDate | date}}</span>
          </span>
        </div>
        <div>
          <span class="col-md-12 comment">
            <label>Comment:</label>
            <textarea class="form-control" [disabled]="!isEditMode" [(ngModel)]="editedLead.comment"></textarea>
          </span>
        </div>
      </div>
      <div class="form-footer">
        <button class="btn btn-primary" (click)="startEdit()" *ngIf="!isEditMode">Edit</button>
        <button class="btn btn-primary" (click)="onCancel()">{{isEditMode ? 'Cancel' : 'Close'}}</button>
        <button class="btn btn-primary" [disabled]="!editedLead.account.id" (click)="onSubmit()" *ngIf="isEditMode">Submit</button>
      </div>
    </div>
  </div>
</div>
