<select-account-modal *ngIf="!!viewReady" [lead]="selectedLead" [accounts]="accounts" (onSubmit)="!!$event && accountSelected($event)"></select-account-modal>
<view-lead-modal *ngIf="!!viewReady" [lead]="selectedLead" [accounts]="accounts" (onHide)="closeViewLead()" (onSubmit)="editLead($event)"></view-lead-modal>
<new-lead-modal *ngIf="!!viewReady" [accounts]="accounts" (onSubmit)="createLead($event)"></new-lead-modal>
<div class="panel panel-sky">
  <div class="panel-heading">
    <h4><i class="fa fa-user-plus"></i> Leads Management</h4>
  </div>
  <div class="panel-body collapse in" style="display: block;">
    <div id="example_wrapper" class="dataTables_wrapper" role="grid">
      <div class="row" style="padding-bottom: 10px;">
        <div class="col-xs-12">
          <button class="btn btn-secondary btn-label" (click)="addNewLead()"><i class="fa fa-user-circle"></i> New Lead</button>
          <span class="dataTables_info">Total of {{totalLeads}} leads</span>
          <!--<button class="btn btn-secondary btn-label" (click)="bulkUserUpload()"><i class="fa fa-users"></i> Bulk Lead Registration</button>-->
        </div>
      </div>
      
      <div *ngIf="viewFailed" class="text-danger">
        Failed to retrieve leads. <a class="text-clickable" (click)="getLeads()">Retry</a>
      </div>
      <div *ngIf="viewReady">
        <div class="row">
          <div class="col-xs-3">
            <div class="dataTables_filter">
              <input type="text" class="form-control" placeholder="Search..."
                     [formControl]="inputSelector">
            </div>
          </div>
          <div class="col-sx-3">
              <button type="button" class="btn btn-primary" (click)="getLeads()">
                <i class="fa fa-refresh"></i> Refresh
              </button>
          </div>
          <div class="col-xs-6 col-xs-offset-3">
            <div id="example_length" class="dataTables_length pull-right">
              <label>
                <select size="1" class="form-control" (change)="pageSizeChange($event.target.value)">
                  <option *ngFor="let listSizeOption of recordsPerPageOptions" [attr.value]="listSizeOption" [selected]="listSizeOption === listSize">{{listSizeOption}}</option>
                </select>
              </label>
              records per page
            </div>
          </div>
        </div>
        <table cellpadding="0" cellspacing="0" border="0" class="table table-striped table-bordered table-hover datatables dataTable">
          <thead>
            <tr>
              <th (click)="sortBy('email')" class="header email">Email<span class="sorting" *ngIf="sortField=='email'"><i class="fa fa-sort-desc" *ngIf="!sortAsc"></i><i class="fa fa-sort-asc" *ngIf="sortAsc"></i></span></th>
              <th (click)="sortBy('status')" class="header status">Status<span class="sorting" *ngIf="sortField=='status'"><i class="fa fa-sort-desc" *ngIf="!sortAsc"></i><i class="fa fa-sort-asc" *ngIf="sortAsc"></i></span></th>
              <th (click)="sortBy('source')" class="header source">Source<span class="sorting" *ngIf="sortField=='source'"><i class="fa fa-sort-desc" *ngIf="!sortAsc"></i><i class="fa fa-sort-asc" *ngIf="sortAsc"></i></span></th>
              <th (click)="sortBy('createdAt')" class="header createdAt">Created at<span class="sorting" *ngIf="sortField=='createdAt'"><i class="fa fa-sort-desc" *ngIf="!sortAsc"></i><i class="fa fa-sort-asc" *ngIf="sortAsc"></i></span></th>
              <th (click)="sortBy('updatedAt')" class="header updatedAt">Updated at<span class="sorting" *ngIf="sortField=='updatedAt'"><i class="fa fa-sort-desc" *ngIf="!sortAsc"></i><i class="fa fa-sort-asc" *ngIf="sortAsc"></i></span></th>
              <th class="not-clickable" class="header comment">Comment</th>
              <th class="not-clickable" class="header account">Account</th>
              <th class="not-clickable" class="header actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="gradeA" *ngFor="let lead of leads | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalLeads}">
              <td class="email" (click)="viewLead(lead)">{{lead.email}}<i class="fa fa-eye" aria-hidden="true"></i></td>
              <td>{{LEAD_STATUS_MAP[lead.status]}}</td>
              <td>{{lead.source}}</td>
              <td>{{lead.createdAt | date}}</td>
              <td>{{lead.updatedAt | date}}</td>
              <td class="text-ellipsis comment">{{lead.comment}}</td>
              <td>{{lead.account?.name}}</td>
              <td>
                <span dropdown class="btn-group" *ngIf="lead.status !== LEAD_STATUS.registered">
                  <button dropdownToggle class="btn btn-primary btn-label"><i class="fa fa-ellipsis-v" aria-hidden="true"></i> Actions </button>
                  <ul *dropdownMenu class="dropdown-menu lead-actions-menu">
                    <li *ngIf="lead.status === LEAD_STATUS.awaitingApproval || lead.status === LEAD_STATUS.declined">
                      <a class="text-clickable" (click)="approveLead(lead)">
                        <i class="fa fa-check"></i> Approve
                      </a>
                    </li>
                    <li *ngIf="lead.status === LEAD_STATUS.registrationInProgress || lead.status === LEAD_STATUS.approvedForRegistration">
                      <a class="text-clickable" (click)="resendInvitation(lead)">
                        <i class="fa fa-envelope-o"></i> Send invitation
                      </a>
                    </li>
                    <li *ngIf="[LEAD_STATUS.approvedForRegistration, LEAD_STATUS.registrationInProgress, LEAD_STATUS.awaitingApproval].includes(lead.status)">
                      <a class="text-clickable" (click)="declineLead(lead)">
                        <i class="fas fa-ban"></i> Decline
                      </a>
                    </li>
                  </ul>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <pagination-template #p="paginationApi"
                (pageChange)="pageChange($event)" class="col-xs-10 col-xs-offset-1">
            <div class="dataTables_paginate paging_bootstrap">
              <ol class="pagination">
                <li class="prev" [class.disabled]="p.isFirstPage()">
                  <a class="text-clickable" (click)="!p.isFirstPage() && p.previous()">
                  <i class="fa fa-long-arrow-left"></i> Previous</a>
                </li>
                
                <li *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                    <a class="text-clickable" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                        <span>{{ page.label }}</span>
                    </a>
                    <a *ngIf="p.getCurrent() === page.value"><span>{{ page.label }}</span></a>
                </li>

                <li class="next" [class.disabled]="p.isLastPage()">
                  <a class="text-clickable" (click)="!p.isLastPage() && p.next()">Next <i class="fa fa-long-arrow-right"></i></a>
                </li>
              </ol>
            </div>
          </pagination-template>
        </div>
      </div>
    </div>
  </div>
</div>
