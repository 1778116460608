<div bsModal #dialog="bs-modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-backdrop" (click)="onCancel()"></div>
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <button type="button" class="close" (click)="onCancel()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <trial *ngIf="modalOpen" [trial]="trial" (cancel)="onCancel()" (submit)="onApply($event)"></trial>
        </div>
    </div>
</div>
