<div bsModal #approxModal="bs-modal" class="modal fade" tabindex="-1">
   <div class="modal-dialog modal-lg">
      <div class="modal-content">
         <div class="modal-header">
            <h3 class="modal-title">{{modalTitle}}</h3>
         </div>
         <div class="modal-body">
            <h4>Report Debug Info</h4>
            <hr/>
            <app-details summary="Subject Details">
               <div [innerHTML]="subjectApartmentHtml"></div>
            </app-details>
            <h3 *ngIf="approxSteps && approxSteps.length">Approx Steps</h3>
            <ol>
               <li *ngFor="let step of approxSteps">
                  <h5>{{step.text}}</h5>
                  <app-details summary="Step Parameters" *ngIf="step.params">
                    <div [innerHTML]="step.paramsHtml"></div>
                  </app-details>
                  <span *ngIf="step.compsCount >= 0">{{step.compsCount}} comps found</span>
                  <span *ngIf="step.compsThatPassStepFilterCount >= 0">{{step.compsThatPassStepFilterCount}} comps passed step's filters</span>
                  <span *ngIf="step.compsThatPassedScoringCount >= 0"><br>{{step.compsThatPassedScoringCount}} left after scoring</span>
                  <span *ngIf="step.minCompsForAvg"><br>Min comps for Avg: {{step.minCompsForAvg}}</span>
                  <app-details *ngIf="step.compsTable" summary="comps data">
                     <div class="debug-table-container">
                        {{step.compsTable | json}}
                        <ngx-datatable [scrollbarH]="'true'" [columns]="step.compsTable.columnDefs" [rows]="step.comps"
                        class="material"></ngx-datatable>
                     </div>
                  </app-details>
                  <app-details *ngIf="step.compsThatPassStepFilterTable" summary="comps data (passed filter)">
                     <div class="debug-table-container">
                        <ngx-datatable [scrollbarH]="'true'" [columns]="step.compsThatPassStepFilterTable.columnDefs" [rows]="step.compsThatPassStepFilter" class="material"></ngx-datatable>
                     </div>
                  </app-details>
                  <app-details *ngIf="step.compsThatPassedScoringTable" summary="comps data (passed scoring)">
                     <div class="debug-table-container">
                        <ngx-datatable [scrollbarH]="'true'" [columns]="step.compsThatPassedScoringTable.columnDefs" [rows]="step.compsThatPassedScoring" class="material"></ngx-datatable>
                     </div>
                  </app-details>
                  <app-details *ngIf="step.upperCapTable" summary="Upper cap comps">
                     <div class="debug-table-container">
                        <ngx-datatable [scrollbarH]="'true'" [columns]="step.upperCapTable.columnDefs" [rows]="step.upperCap"
                        class="material"></ngx-datatable>
                     </div>
                  </app-details>
                  <app-details *ngIf="step.lowerCapTable" summary="Lower cap comps">
                     <div class="debug-table-container">
                        <ngx-datatable [scrollbarH]="'true'" [columns]="step.lowerCapTable.columnDefs" [rows]="step.lowerCap"
                        class="material"></ngx-datatable>
                     </div>
                  </app-details>
                  <span *ngIf="step.approx" style="font-weight: 700; background-color: forestgreen;"><br>Approx: ${{step.approx | number: '1.0-0' }}</span>
               </li>
            </ol>
            <h3 *ngIf="selectionStorySteps && selectionStorySteps.length">Selection Steps</h3>
            <ol>
               <li *ngFor="let step of selectionStorySteps">
                  <h5>{{step.text}}</h5>
                  <span>{{step.compCount}} Comps found</span><br>
                  <span *ngIf="step.duration">Duration: {{step.duration}} (ms)</span>
                  <app-details summary="step parameters">
                     <div [innerHTML]="step.paramsHtml"></div>
                  </app-details>
               </li>
            </ol>
         </div>
         <div class="modal-footer">
            <button class="btn btn-primary" type="button" (click)="onClose.emit()">Close</button>
        </div>
      </div>
   </div>
</div>
