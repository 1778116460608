<div class="panel panel-sky">
  <div class="panel-heading">
    <h4><i class="fa fa-key"></i> Permissions Management</h4>
  </div>
  <div class="panel-body">
    <div class="row actions">
      <div class="col-xs-4">
        <button class="btn btn-primary btn-label" (click)="createRole()"><i class="fa fa-plus" aria-hidden="true"></i> New Role</button>
        <!-- routerLink="/main/permissions/new" -->
      </div>
    </div>
    <div class="row">
      <div class="panel panel-sky col-xs-2">
        <div class="panel-heading">
          Roles
        </div>
        <div class="panel-body inner">
          <ul class="generic-list">
            <li class="list-item" [class.selected]="role.name === roleName" *ngFor="let role of roles" (click)="editRole(role.name)">
              <span>{{role.displayName}}</span><!--  routerLink="/main/permissions/edit/{{role.name}}" -->
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xs-10">
        <app-add-edit-role *ngIf="newRole || roleName" (submit)="updateRole($event)" (cancel)="close()" [roleName]="roleName"></app-add-edit-role>
      </div>
    </div>
  </div>
</div>
