<div bsModal #resetDisclaimerModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        Reset Disclaimer
      </div>
      <div class="modal-body">
        You are about to reset <b>{{user.firstName}} {{user.lastName}}</b>'s disclaimer. Are you sure you want to proceed?
      </div>
      <div class="modal-footer">
          <button [disabled]="isResetting" class="btn btn-primary" (click)="onClose.emit()">No</button>
          <button [disabled]="isResetting" class="btn btn-warning" (click)="resetDisclaimer()">Yes</button>
      </div>
    </div>
  </div>
</div>
