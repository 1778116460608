<div class="verticalcenter">
	<img src="../../assets/imgs/CompitLogo.svg" alt="Logo" class="brand">
	<div class="panel panel-primary">
		<form [formGroup]="form" (ngSubmit)="performLogin(form.value)">
			<div class="panel-body">
				<h4 class="text-center" style="margin-bottom: 25px;">Log in to get started</h4>
				<div class="form-horizontal" style="margin-bottom: 0px !important;">
					<div class="form-group">
						<div class="col-sm-12">
							<div class="input-group">
								<span class="input-group-addon"><i class="fa fa-user"></i></span>
								<input type="text" class="form-control" id="username" formControlName="username" placeholder="Username" />
							</div>
						</div>
					</div>
					<div class="form-group">
						<div class="col-sm-12">
							<div class="input-group">
								<span class="input-group-addon"><i class="fa fa-lock"></i></span>
								<input type="password" class="form-control" id="password" formControlName="password" placeholder="Password" />
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="panel-footer">
				<div class="pull-right">
					<button type="submit" [disabled]="loading" class="btn btn-primary text-clickable">Log In</button>
				</div>
			</div>
		</form>
	</div>
</div>
