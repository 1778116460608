<i class="loader fa fa-spinner fa-pulse fa-3x fa-fw" *ngIf="loadingPermissions"></i>
<div class="row" *ngIf="!loadingPermissions">
  <div *ngFor="let permissionGroup of permissions" class="col-xs-12">
    <div class="panel panel-sky">
      <div class="panel-heading">
        <h4>{{permissionGroup.displayName}}</h4>
      </div>
      <div class="panel-body inner">
        <ul class="generic-list">
          <li *ngFor="let permission of permissionGroup.values; let i = index" class="list-item" [class.divider]="i + 1 < permissionGroup.values.length" [class.selected]="isSelected(permission)">
            <label>
              <input type="checkbox" [checked]="isSelected(permission)" (click)="select(permission)" />
              {{permission.displayName}}
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
