<div class="select-account">
  <div class="form-box">
    <div class="form-holder">
      <h3 class="form-title">Select Account</h3>
      <h4 class="form-title secondary-title">for: {{lead.email}}</h4>
      <div class="form-body">
        <select class="form-control" [(ngModel)]="selectedAccount">
          <option *ngFor="let account of accounts" [value]="account.id">
            {{account.name}}
          </option>
        </select>
      </div>
      <div class="form-footer">
        <button class="btn btn-primary" (click)="onCancel()">Cancel</button>
        <button class="btn btn-primary" [disabled]="!selectedAccount" (click)="onSubmit()">Submit</button>
      </div>
    </div>
  </div>
</div>
