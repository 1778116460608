<div bsModal #resetPasswordModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
          <h3 class="modal-title">Reset Password</h3>
      </div>
      <div class="modal-body">
        <span *ngIf="generatedPassword === '' && !isResetting">
          You are about to reset {{user.firstName}} {{user.lastName}}'s password. Are you sure you want to proceed?
        </span>
        <span *ngIf="isResetting">Resetting password, please wait...</span>
        <span *ngIf="generatedPassword.length > 0">
          The password has been resetted successfully, the new password is <input type="text" [(ngModel)]="generatedPassword" id="new-password" />
        </span>
      </div>
      <div class="modal-footer">
        <div *ngIf="generatedPassword.length === 0">
          <button [disabled]="isResetting" class="btn btn-primary" (click)="onClose.emit()">No</button>
          <button [disabled]="isResetting" class="btn btn-warning" (click)="resetPassword()">Yes</button>
        </div>
        <div *ngIf="generatedPassword.length > 0">
          <button class="btn btn-primary" (click)="onClose.emit()">Close</button>
        </div>
      </div>

    </div>
  </div>
</div>
