<div bsModal #bulkUserUploadConfirmationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        Registering {{usersCount}} users
      </div>
      <div class="modal-body">
        <span *ngIf="errorsCount !== usersCount">
          You are about to upload {{usersCount}} users.
          <span *ngIf="errorsCount" class="text-danger">
            <br />
            {{errorsCount}} out of them are invalid and will not be registered.
            You may edit those users before proceeding by editing the users table.
          </span>
          <br />
          Are you sure you want to proceed?
        </span>
        <span *ngIf="errorsCount === usersCount" class="text-danger">
          No users will be registered since all of them are invalid. Please go back and fix them.
        </span>
      </div>
      <div class="modal-footer">
          <span *ngIf="errorsCount !== usersCount">
            <button class="btn btn-primary" (click)="onClose.emit()">No</button>
            <button class="btn btn-warning" (click)="registerUsers()">Yes</button>
          </span>
          <span *ngIf="errorsCount === usersCount">
            <button class="btn btn-warning" (click)="onClose.emit()">Go back</button>
          </span>
      </div>
    </div>
  </div>
</div>
