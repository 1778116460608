<div class="panel panel-sky">
  <div class="panel-heading">
    <h4><i class="fa fa-briefcase"></i> Account Management</h4>
  </div>
  <div class="panel-body collapse in" style="display: block;">
    <div id="example_wrapper" class="dataTables_wrapper" role="grid">
      <div class="row" style="padding-bottom: 10px;">
        <div class="col-xs-12">
          <button class="btn btn-secondary btn-label" (click)="addNewAccount()"><i class="fa fa-briefcase"></i> New
            Account
          </button>

          <span class="dataTables_info">Total of {{totalAccounts}} accounts</span>
          <!--<button class="btn btn-secondary btn-label" (click)="bulkUserUpload()"><i class="fa fa-users"></i> Bulk Lead Registration</button>-->
        </div>
      </div>

      <div *ngIf="viewFailed" class="text-danger">
        Failed to retrieve accounts. <a class="text-clickable" (click)="getAccounts()">Retry</a>
      </div>
      <div *ngIf="viewReady">
        <div class="row filter-row">
          <div class="col-xs-3">
            <div class="dataTables_filter">
              <input type="text" class="form-control" placeholder="Search..."
                     [formControl]="inputSelector">
            </div>
          </div>
          <div class="col-xs-2">
            <div *ngIf="!!typeOptions && typeOptions.length">
              <label>Type:</label>
              <ss-multiselect-dropdown [settings]="dropdownSettings" [options]="typeOptions" [(ngModel)]="typeModel"
                                       (ngModelChange)="onFilterChange($event)"></ss-multiselect-dropdown>
            </div>
          </div>
          <div class="col-xs-2">
            <div *ngIf="!!planTypeOptions && planTypeOptions.length">
              <label>Plan Type:</label>
              <ss-multiselect-dropdown [settings]="dropdownSettings" [options]="planTypeOptions"
                                       [(ngModel)]="planTypeModel"
                                       (ngModelChange)="onFilterChange($event)"></ss-multiselect-dropdown>
            </div>
          </div>
          <div class="col-xs-2">
            <div *ngIf="!!planStatusOptions && planStatusOptions.length">
              <label>Plan Status:</label>
              <ss-multiselect-dropdown [settings]="dropdownSettings" [options]="planStatusOptions"
                                       [(ngModel)]="planStatusModel"
                                       (ngModelChange)="onFilterChange($event)"></ss-multiselect-dropdown>
            </div>
          </div>
          <div class="col-xs-2 col-xs-offset-1">
            <div id="example_length" class="dataTables_length pull-right">
              <label>
                <select size="1" class="form-control" (change)="pageSizeChange($event.target.value)">
                  <option *ngFor="let listSizeOption of recordsPerPageOptions" [attr.value]="listSizeOption"
                          [selected]="listSizeOption === listSize">{{listSizeOption}}
                  </option>
                </select>
              </label>
              records per page
            </div>
          </div>
        </div>
        <table cellpadding="0" cellspacing="0" border="0"
               class="table table-striped table-bordered table-hover datatables dataTable">
          <thead>
          <tr>
            <th (click)="sortBy('name')" class="header name">Account<span class="sorting" *ngIf="sortField=='name'"><i
              class="fa fa-sort-desc" *ngIf="!sortAsc"></i><i class="fa fa-sort-asc" *ngIf="sortAsc"></i></span></th>
            <th (click)="sortBy('type')" class="header type">Type<span class="sorting" *ngIf="sortField=='type'"><i
              class="fa fa-sort-desc" *ngIf="!sortAsc"></i><i class="fa fa-sort-asc" *ngIf="sortAsc"></i></span></th>
            <th class="header planType not-clickable">Plan Type</th>
            <th class="header planStatus not-clickable">Plan Status</th>
            <th class="header planEndDate not-clickable">Plan Date End</th>
            <th (click)="sortBy('registeredUsers')" class="header registeredUsers">Registered Users<span class="sorting"
                                                                                                         *ngIf="sortField=='registeredUsers'"><i
              class="fa fa-sort-desc" *ngIf="!sortAsc"></i><i class="fa fa-sort-asc" *ngIf="sortAsc"></i></span></th>
            <th (click)="sortBy('endingBalance')" class="header endingBalance">Ending Balance ($)<span class="sorting"
                                                                                                       *ngIf="sortField=='endingBalance'"><i
              class="fa fa-sort-desc" *ngIf="!sortAsc"></i><i class="fa fa-sort-asc" *ngIf="sortAsc"></i></span></th>
            <th class="header alerts not-clickable">Alerts</th>
          </tr>
          </thead>
          <tbody>
          <tr class="gradeA"
              *ngFor="let account of accounts | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalAccounts}">
            <td class="name" (click)="viewAccount(account)">{{account.name}}<i class="fa fa-eye" aria-hidden="true"></i>
            </td>
            <td>{{account.type}}</td>
            <td>{{account.planType}}</td>
            <td>{{account.plan?.status}}</td>
            <td>{{account.plan?.endDate | date}}</td>
            <td><i *ngIf="loadingStatistics" class="fa fa-spinner fa-pulse"></i>{{account.registeredUsers}}</td>
            <td>{{account.endingBalance}}</td>
            <td>{{account.alerts}}</td>
          </tr>
          </tbody>
        </table>
        <div class="row">
          <pagination-template #p="paginationApi"
                               (pageChange)="pageChange($event)" class="col-xs-10 col-xs-offset-1">
            <div class="dataTables_paginate paging_bootstrap">
              <ol class="pagination">
                <li class="prev" [class.disabled]="p.isFirstPage()">
                  <a class="text-clickable" (click)="!p.isFirstPage() && p.previous()">
                    <i class="fa fa-long-arrow-left"></i> Previous</a>
                </li>

                <li *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                  <a class="text-clickable" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                    <span>{{ page.label }}</span>
                  </a>
                  <a *ngIf="p.getCurrent() === page.value"><span>{{ page.label }}</span></a>
                </li>

                <li class="next" [class.disabled]="p.isLastPage()">
                  <a class="text-clickable" (click)="!p.isLastPage() && p.next()">Next <i
                    class="fa fa-long-arrow-right"></i></a>
                </li>
              </ol>
            </div>
          </pagination-template>
        </div>
      </div>
    </div>
  </div>
</div>
