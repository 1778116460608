<div class="select-account">
  <div class="form-box">
    <div class="form-holder">
      <h3 class="form-title">Upload new image</h3>
      <div class="form-body">
        <div class="row">
          <div class="col-xs-12">
            <div class="fileinput fileinput-new" data-provides="fileinput">
      <span class="btn btn-default btn-file">
        <i class="fa fa-upload"></i>
        <span class="fileinput-new">Upload new image</span>
        <input type="file" name="..." accept="image/*" (change)="uploadNew($event)">
      </span>
              <span class="fileinput-filename"></span>
              <a href="#" class="close fileinput-exists" data-dismiss="fileinput" style="float: none">×</a>
            </div>
          </div>
        </div>
        <div class="canvas-container"
             [style.width]="editImage.width + editImage.paddingLeft + editImage.paddingRight + 85 + 'px'"
             [style.height]="editImage.height + editImage.paddingTop + editImage.paddingBottom + 80 + 'px'">
          <div class="top">
            <input type="button" value="-" (click)="addPadding('top', -10)"/>
            <input type="button" value="+" (click)="addPadding('top', 10)"/>
          </div>
          <div class="bottom">
            <input type="button" value="-" (click)="addPadding('bottom', -10)"/>
            <input type="button" value="+" (click)="addPadding('bottom', 10)"/>
          </div>
          <div class="left">
            <input type="button" value="-" (click)="addPadding('left', -10)"/>
            <input type="button" value="+" (click)="addPadding('left', 10)"/>
          </div>
          <div class="right">
            <input type="button" value="-" (click)="addPadding('right', -10)"/>
            <input type="button" value="+" (click)="addPadding('right', 10)"/>
          </div>
          <canvas #canvas [style.background]="editImage.color"
                  [width]="editImage.width + editImage.paddingLeft + editImage.paddingRight"
                  [height]="editImage.height + editImage.paddingTop + editImage.paddingBottom"></canvas>
        </div>
        <div class="row">
          <div class="col-xs-12">
            Preview image with ratio: <input type="text" [(ngModel)]="editImage.heightRatio" class="height-ratio"/>px in
            height
          </div>
          <div class="row">
            <div class="col-xs-12">
              <img class="preview-image" crossorigin="anonymous" [style.background]="editImage.color"
                   [src]="editImage.previewUrl" [style.height]="editImage.heightRatio + 'px'"/>
            </div>
          </div>
        </div>
      </div>
      <div class="form-footer">
        <button class="btn btn-primary" (click)="cancel.emit()">Cancel</button>
        <button class="btn btn-primary" (click)="updateImage()">Update image</button>
      </div>
    </div>
  </div>
</div>
