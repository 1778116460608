<div class="select-account">
  <div class="form-box">
    <div class="form-holder">
      <h3 class="form-title">Edit Plan</h3>
      <div class="form-body">
        <div class="row">
          <label class="col-xs-3">Pricing Plan:</label>
          <span class="col-xs-9">
            <select size="1" class="form-control" [(ngModel)]="plan.id"
                    (change)="changeStripePlan()">
              <option *ngFor="let stripePlan of plans" [attr.value]="stripePlan.id"
                      [selected]="stripePlan.id === plan.id">{{stripePlan.nickname}}
              </option>
            </select>
          </span>
        </div>
        <div class="row">
          <label class="col-xs-3">Seats:</label>
          <span class="col-xs-9 input-holder">
            <input type="number" [min]="1" class="form-control" (change)="seatsChanged()"
                   [(ngModel)]="plan.seats" placeholder="Seats" min="1">
            <label class="error" *ngIf="!!errors.seats">{{errors.seats}}</label>
          </span>
        </div>
        <div class="row">
          <label class="col-xs-3">Price Per Seat:</label>
          <span class="col-xs-9">
            <input type="number" disabled class="form-control" [(ngModel)]="plan.pricePerSeat"
                   placeholder="Price Per Seat" min="1">
          </span>
        </div>
        <div class="row">
          <label class="col-xs-3">Total:</label>
          <span class="col-xs-9">
            <input type="number" disabled class="form-control" [(ngModel)]="plan.total" placeholder="Total"
                   min="1">
          </span>
        </div>
        <div class="row">
          <label class="col-xs-3">Start Date:</label>
          <span class="col-xs-9">
            <div class="form-control" disabled="">
              <span *ngIf="!!plan.startDate">
                {{plan.startDate | date:'yyyy-MM-dd'}}
              </span>
            </div>
          </span>
        </div>
        <div class="row">
          <label class="col-xs-3">End Date:</label>
          <span class="col-xs-9 input-holder">
            <input class="form-control" type="date" [ngModel]="(plan.endDate | date:'yyyy-MM-dd') ? (plan.endDate | date:'yyyy-MM-dd') : ''"
                   (ngModelChange)="plan.endDate = $event">
            <label class="error" *ngIf="!!errors.date">{{errors.date}}</label>
          </span>
        </div>
        <div class="row">
          <label class="col-xs-3">Show User:</label>
          <span class="col-xs-9">
            <input type="checkbox" class="form-control" [(ngModel)]="plan.isVisible" (change)="validateChanges()">
          </span>
        </div>
        <div class="row">
          <label class="col-xs-3">Status:</label>
          <span class="col-xs-9">
            <select size="1" class="form-control" disabled [(ngModel)]="plan.status">
              <option *ngFor="let status of PLAN_STATUSES"
                      [selected]="status === plan.status">{{status}}
              </option>
            </select>
          </span>
        </div>
      </div>
      <div class="form-footer">
        <button class="btn btn-primary" (click)="onCancel()">Cancel</button>
        <button class="btn btn-primary"
                [disabled]="!changesMade || !isValid"
                (click)="onSubmit()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
