<div class="panel panel-sky">
  <div class="panel-heading">
    <h4>
      <i class="fa fa-image"></i> Image Validation</h4>
  </div>
  <div class="panel-body collapse in" style="display: block;">
    <div class="tab-container tab-info">
      <ul class="nav nav-tabs">
        <li [class.active]="selectedTab === 'sale'" class="active">
          <a class="text-clickable" (click)="selectTab('sale')">Sale</a>
        </li>
        <li [class.active]="selectedTab === 'rent'">
          <a class="text-clickable" (click)="selectTab('rent')">Rent</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="input-group well search-container">
          <input [(ngModel)]="typeaheadValue" placeholder="Search apartments..." [typeahead]="asyncResults" #typeahead [typeaheadAsync]="true"
            (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionField]="'name'"
            class="form-control" />
        </div>
      </div>
    </div>
  </div>


  <div class="images-container">
    <div *ngFor="let image of images" class="image-container">
      <div class="{{image.isValidUrl ? 'valid' : 'invalid'}}-image-header image-header">
        <label *ngIf="image.isValidUrl">Valid Url</label>
        <label *ngIf="!image.isValidUrl">Invalid Url</label>
      </div>
      <img [src]="image.url" />
      <div class="image-info">
        <div class="image-info-line">
          <button type="button" class="btn btn-success" [disabled]="image.isValidUrl" (click)="toggleImageValidation(image, true)">
            Validate
          </button>

          <button type="button" class="btn btn-danger" [disabled]="!image.isValidUrl" (click)="toggleImageValidation(image, false)">
            Invalidate
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
