<div class="select-account">
  <div class="form-box">
    <div class="form-holder">
      <h3 class="form-title">Trial Mode Details</h3>
      <div class="form-body">
        <div class="row">
          <label class="col-xs-3">Seats:</label>
          <input class="form-control col-xs-9" [(ngModel)]="trial.seats" placeholder="Seats" min="1">
        </div>
        <div class="row">
          <label class="col-xs-3">Start Date:</label>
          <input class="form-control" type="date" [ngModel] ="trial.startDate | date:'yyyy-MM-dd'" (ngModelChange)="trial.startDate = $event">
        </div>
        <div class="row">
          <label class="col-xs-3">End Date:</label>
          <input class="form-control" type="date" [ngModel] ="trial.endDate | date:'yyyy-MM-dd'" (ngModelChange)="trial.endDate = $event">
        </div>
      </div>
      <div class="form-footer">
        <button class="btn btn-primary" (click)="onCancel()">Cancel</button>
        <button class="btn btn-primary" [disabled]="!trial.seats || !trial.startDate || !trial.endDate || trial.startDate > trial.endDate" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
