<div class="select-account">
  <div class="form-box">
    <div class="form-holder">
      <h3 class="form-title">Create new lead</h3>
      <div class="form-body">
        <input class="form-control" [(ngModel)]="email" placeholder="Enter lead email" (input)="isEmailValid($event.target.value)">
        <select class="form-control" [(ngModel)]="selectedAccount">
          <option *ngFor="let account of accounts" [value]="account.id">
            {{account.name}}
          </option>
        </select>
      </div>
      <div class="form-footer">
        <button class="btn btn-primary" (click)="onCancel()">Cancel</button>
          <button class="btn btn-primary" [disabled]="!selectedAccount || !email || !emailValid" (click)="onSubmit()">Submit</button>
      </div>
    </div>
  </div>
</div>
