<cancel-subscription-modal [subscriptionId]="cancelSubscriptionId" (onSubmit)="onCancelSubscription($event)">
</cancel-subscription-modal>
<image-edit-modal (onSubmit)="updateImage($event)" (onHide)="closeImageEdit()" [imageUrl]="imageUrl"
                  [defaultImageUrl]="defaultImageUrl"></image-edit-modal>
<add-edit-user-modal (onSubmit)="userSaved()" (onHide)="userSaved()" [accounts]="accounts" [roles]="roles"
                     [officeAddresses]="userModalDetails.officeAddresses"
                     [userId]="userModalDetails.userId"></add-edit-user-modal>
<trial-modal (onSubmit)="onTrialEdit($event)" [trial]="editTrial"></trial-modal>
<edit-plan-modal (onSubmit)="onPlanChange($event)" (onHide)="cancelEditPlan()" [plan]="editPlan" [plans]="stripePlans"></edit-plan-modal>
<div class="panel panel-sky">
  <div class="panel-heading">
    <h4><i class="fa fa-briefcase"></i> {{!!account?.id ? 'Edit' : 'Add'}} Account</h4>
    <button class="btn btn-primary btn-label" (click)="goBack()">{{!!account?.id ? 'Back' : 'Cancel'}}</button>
    <button class="btn btn-primary btn-label btn-save" [disabled]="!changesMade || !isValid" (click)="saveAccount()">
      Save
    </button>
    <button class="btn btn-primary btn-label btn-refresh" *ngIf="!!account?.id" (click)="ngOnInit()">
      Refresh
    </button>
  </div>
  <div class="panel-body collapse in" style="display: block;" *ngIf="viewReady">
    <div>
      <h3>
        Account
      </h3>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <div class="row">
          <div class="col-sm-1">
            <label>Name</label>
          </div>
          <div class="col-sm-5 input-holder">
            <input [(ngModel)]="account.name" (input)="checkValidity()" [ngClass]="{'error': !!errors.name}">
            <label class="error" *ngIf="!!errors.name">{{errors.name}}</label>
          </div>
          <div class="col-sm-1">
            <label>Domains</label>
          </div>
          <div class="col-sm-5 input-holder">
            <input [(ngModel)]="domains" (input)="checkValidity()" [ngClass]="{'error': !!errors.domains}">
            <label class="error" *ngIf="!!errors.domains">{{errors.domains}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-1">
            <label>Company</label>
          </div>
          <div class="col-sm-5 input-holder">
            <input [(ngModel)]="account.company" (input)="checkValidity()" [ngClass]="{'error': !!errors.company}">
            <label class="error" *ngIf="!!errors.company">{{errors.company}}</label>
          </div>
          <div class="col-sm-1">
            <label>Auto Approved</label>
          </div>
          <div class="col-sm-5">
            <input type="checkbox" [(ngModel)]="account.isAutoApproved" (change)="checkValidity()">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-1">
            <label>Type</label>
          </div>
          <div class="col-sm-5">
            <select size="1" class="form-control billing-admin" [(ngModel)]="account.type" (change)="checkValidity()">
              <option *ngFor="let type of ACCOUNT_TYPES" [attr.value]="type"
                      [selected]="type === account.type">{{type}}
              </option>
            </select>
          </div>
          <div class="col-sm-1">
            <label>Address</label>
          </div>
          <div class="col-sm-5">
            <input [(ngModel)]="account.address" (input)="checkValidity()">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-1">
            <label>Roles</label>
          </div>
          <div class="col-sm-5">
            <select size="1" class="form-control billing-admin" [(ngModel)]="account.roles[0]"
                    (change)="checkValidity()">
              <option *ngFor="let role of roles" [attr.value]="role._id"
                      [selected]="role._id === account.roles[0]">{{role.name}}
              </option>
            </select>
          </div>
          <div class="col-sm-1">
            <label>Created</label>
          </div>
          <div class="col-sm-5">
            <div class="form-control" disabled="">
              <span *ngIf="!!account.createdOn">
                {{account.createdOn | date:'yyyy-MM-dd'}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-2">
                <label>Print Logo</label>
              </div>
              <div class="col-sm-10 img-limit-size" [class.not-invalidation]="accountLogos.print.invalidation">
                <img onerror="this.src='//media.compit.com/branding/compit/print_logo.png'"
                     [src]="accountLogos.print.url" *ngIf="!accountLogos.print.isPending"/>
                <a (click)="editImage('print')" class="text-clickable edit-image"
                   *ngIf="!accountLogos.print.invalidation"><i class="fa fa-pencil"></i> Edit</a>
                <span *ngIf="accountLogos.print.isPending"><i tooltip="Image is being uploaded to server..."
                                                              class="pending-loader fa fa-spinner fa-pulse fa-fw"></i></span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                <label>Share Logo</label>
              </div>
              <div class="col-sm-10 img-limit-size" [class.not-invalidation]="accountLogos.share.invalidation">
                <img onerror="this.src='//media.compit.com/branding/compit/share_logo.png'"
                     [src]="accountLogos.share.url" *ngIf="!accountLogos.share.isPending"/>
                <a (click)="editImage('share')" class="text-clickable edit-image"
                   *ngIf="!accountLogos.share.invalidation"><i class="fa fa-pencil"></i> Edit</a>
                <span *ngIf="accountLogos.share.isPending"><i tooltip="Image is being uploaded to server..."
                                                              class="pending-loader fa fa-spinner fa-pulse fa-fw"></i></span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 row-no-bottom-margin">
            <div class="row">
              <div class="col-sm-2">
                <label>Registered Users</label><i *ngIf="loadingStatistics" class="fa fa-spinner fa-pulse local-spinner"></i>
              </div>
              <div class="col-sm-10">
                <input [(ngModel)]="account.registeredUsers" [disabled]="true">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                <label>Deactivated Users</label><i *ngIf="loadingStatistics" class="fa fa-spinner fa-pulse local-spinner"></i>
              </div>
              <div class="col-sm-10">
                <input [(ngModel)]="account.deactivatedUsers" [disabled]="true">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                <label>Active Users</label><i *ngIf="loadingStatistics" class="fa fa-spinner fa-pulse local-spinner"></i>
              </div>
              <div class="col-sm-10">
                <input [(ngModel)]="account.activeUsers" [disabled]="true">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-3">
            <label>Billing Admin</label>
          </div>
          <div class="col-sm-9">
            <select size="1" class="form-control billing-admin" [(ngModel)]="account.billingAdmin.userId" (change)="checkValidity()">
              <option disabled hidden [value]="emptyBillingAdmin">-- select --</option>
              <option *ngFor="let user of activeUsers" [ngValue]="user.id"
                      [selected]="user.id == billingAdminId">{{user.firstName}} {{user.lastName}} ({{user.email}})
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <label>Comments</label>
          </div>
          <div class="col-sm-9">
            <textarea class="form-control" [(ngModel)]="account.comment" [rows]="13"
                      (input)="checkValidity()"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-body trial" *ngIf="viewReady && !!account?.id">
    <div class="row">
      <div class="col-xs-6">
        <h3>
          Trial
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <table cellpadding="0" cellspacing="0" border="0"
               class="table table-striped table-bordered table-hover datatables dataTable">
          <thead>
          <tr>
            <th>Seats</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
          </tr>
          </thead>

          <tbody>
          <tr class="gradeA">
            <td (click)="openTrialDialog()" class="text-clickable"><i class="fa fa-pencil" aria-hidden="true"></i><a>{{account.trial.seats}}</a></td>
            <td><span *ngIf="!!account.trial.startDate">{{account.trial.startDate | date}}</span></td>
            <td><span *ngIf="!!account.trial.endDate">{{account.trial.endDate | date}}</span></td>
            <td class="trialStatus"><span *ngIf="!!account.trial.status"
                                          [ngClass]="{'active': account.trial.status === 'Active',
                                                      'ended': account.trial.status === 'Ended',
                                                      'pending': account.trial.status === 'Pending'}">
              {{account.trial.status}}
            </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="panel-body plan" *ngIf="viewReady">
    <div class="row">
      <div class="col-xs-6">
        <h3>
          Plan
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <table cellpadding="0" cellspacing="0" border="0"
               class="table table-striped table-bordered table-hover datatables dataTable">
          <thead>
          <tr>
            <th>Pricing Plan</th>
            <th>Seats</th>
            <th>Price Per Seat</th>
            <th>Total</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Visible</th>
            <th>Status</th>
          </tr>
          </thead>

          <tbody>
          <tr class="gradeA">
            <td (click)="openEditPlanDialog()" class="text-clickable"><i class="fa fa-pencil" aria-hidden="true"></i><a> {{account.plan.name}}</a></td>
            <td>{{account.plan.seats}}</td>
            <td>{{account.plan.pricePerSeat}}</td>
            <td>{{account.plan.total}}</td>
            <td><span *ngIf="!!account.plan.startDate">{{account.plan.startDate | date}}</span></td>
            <td><span *ngIf="!!account.plan.endDate">{{account.plan.endDate | date}}</span></td>
            <td>{{account.plan.isVisible ? 'Yes' : 'No'}}</td>
            <td class="trialStatus"><span *ngIf="!!account.plan.status"
                                          [ngClass]="{'active': account.plan.status === 'Active',
                                                      'ended': account.plan.status === 'Ended',
                                                      'pending': account.plan.status === 'Pending'}">
              {{account.plan.status}}
            </span>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="panel-body subscriptions" *ngIf="viewReady && !!account?.id">
    <div class="row">
      <div class="col-xs-6">
        <h3>
          Stripe Subscriptions
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <table cellpadding="0" cellspacing="0" border="0"
               class="table table-striped table-bordered table-hover datatables dataTable">
          <thead>
          <tr>
            <th>Pricing Plan</th>
            <th>Seats</th>
            <th>Total ($)</th>
            <th>Date Start</th>
            <th>Stripe Link</th>
            <th>Actions</th>
          </tr>
          </thead>

          <tbody>
          <tr class="gradeA" *ngFor="let subscription of subscriptions">
            <td>{{subscription.plan.nickname}}</td>
            <td>{{subscription.quantity}}</td>
            <td>{{((subscription.plan.amount / 100) * subscription.quantity * (1 + (subscription.tax_percent / 100))).toFixed(2)}}</td>
            <td>{{subscription.start * 1000 | date: 'MMM dd, yyyy'}}</td>
            <td><a [href]="'https://dashboard.stripe.com' + (isProd ? '' : '/test') + '/subscriptions/' + subscription.id" target="_blank"><i class="fa fa-link" aria-hidden="true"></i></a></td>
            <td (click)="cancelSubscription(subscription.id)" class="text-clickable">
                <span *ngIf="subscription.status === 'active'">
                    <a ><i class="fa fa-remove" aria-hidden="true"></i>Cancel</a>
                </span>
              </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="panel-body invoices" *ngIf="viewReady && !!account?.id">
    <div class="row">
      <div class="col-xs-6">
        <h3>
          Stripe Invoices
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <table cellpadding="0" cellspacing="0" border="0"
               class="table table-striped table-bordered table-hover datatables dataTable">
          <thead>
          <tr>
            <th>Pricing Plan</th>
            <th>Billing Period</th>
            <th>Total ($)</th>
            <th>Invoice</th>
          </tr>
          </thead>

          <tbody>
          <tr class="gradeA" *ngFor="let invoice of invoices">
            <td>{{!!invoice.lines.data[0]?.plan ? invoice.lines.data[0].plan.nickname : ''}}</td>
            <td>{{invoice.lines.data[0].period.start * 1000 | date: 'MMM dd, yyyy'}} - {{invoice.lines.data[0].period.end * 1000 | date: 'MMM dd, yyyy'}}</td>
            <td>{{(invoice.total / 100).toFixed(2)}}</td>
            <td><a [href]="invoice.hosted_invoice_url" target="_blank"><i class="fa fa-link" aria-hidden="true"></i></a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="panel-body users" *ngIf="viewReady && !!account?.id">
    <div class="row">
      <div class="col-xs-6">
        <h3>
          Users
        </h3>
      </div>
      <div class="col-xs-6">
        <div id="example_length" class="dataTables_length pull-right">
          <label>
            <select size="1" class="form-control" (change)="pageSizeChange($event.target.value)">
              <option *ngFor="let listSizeOption of recordsPerPageOptions" [attr.value]="listSizeOption"
                      [selected]="listSizeOption === listSize">{{listSizeOption}}
              </option>
            </select>
          </label>
          records per page
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <table cellpadding="0" cellspacing="0" border="0"
               class="table table-striped table-bordered table-hover datatables dataTable">
          <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>
            <th>IsActive</th>
            <th>Actions</th>
          </tr>
          </thead>

          <tbody>
          <tr class="gradeA"
              *ngFor="let user of users | paginate: { itemsPerPage: usersPageSize, currentPage: usersCurrentPage, totalItems: totalUsers}">
            <td><a class="text-clickable" (click)="viewUser(user.userId)">{{user.firstName}} {{user.lastName}}</a></td>
            <td>{{user.email}}</td>
            <td>
              <span class="badge" [class.badge-success]="user.isActive" [class.badge-danger]="!user.isActive">
                {{user.isActive ? 'Active' : 'Disabled'}}
              </span>
            </td>
            <td>
              <span dropdown class="btn-group">
                <button dropdownToggle class="btn btn-primary btn-label"><i class="fa fa-ellipsis-v"
                                                                            aria-hidden="true"></i> More </button>
                <ul *dropdownMenu class="dropdown-menu user-actions-menu">
                  <li>
                    <a class="text-clickable" (click)="unsubscribeAutoUpdates(user)">
                     <i class="fa fa-envelope-o"></i> Unsubscribe AU
                    </a>
                  </li>
                  <li>
                    <a class="text-clickable" (click)="openResetPassword(user)">
                      <i class="fa fa-key"></i> Reset Password
                    </a>
                  </li>
                  <li>
                    <a class="text-clickable" (click)="openResetDisclaimer(user)">
                      <i class="fa fa-book" aria-hidden="true"></i> Reset Disclaimer
                    </a>
                  </li>
                  <li>
                    <a *ngIf="user.isActive" class="text-clickable" (click)="changeUserActivationStatus(user, false)">
                      <i class="fa fa-times-circle-o text-danger" aria-hidden="true"></i> Deactivate User
                    </a>
                    <a *ngIf="!user.isActive" class="text-clickable" (click)="changeUserActivationStatus(user, true)">
                      <i class="fa fa-check-circle-o text-success" aria-hidden="true"></i> Activate User
                    </a>
                  </li>
                </ul>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="viewReady && !!account?.id">
      <pagination-template #p="paginationApi"
                           (pageChange)="usersPageChange($event)" class="col-xs-10 col-xs-offset-1">
        <div class="dataTables_paginate paging_bootstrap">
          <ol class="pagination">
            <li class="prev" [class.disabled]="p.isFirstPage()">
              <a class="text-clickable" (click)="!p.isFirstPage() && p.previous()">
                <i class="fa fa-long-arrow-left"></i> Previous</a>
            </li>

            <li *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
              <a class="text-clickable" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </a>
              <a *ngIf="p.getCurrent() === page.value"><span>{{ page.label }}</span></a>
            </li>

            <li class="next" [class.disabled]="p.isLastPage()">
              <a class="text-clickable" (click)="!p.isLastPage() && p.next()">Next <i
                class="fa fa-long-arrow-right"></i></a>
            </li>
          </ol>
        </div>
      </pagination-template>
    </div>
  </div>
</div>
<app-reset-password-modal *ngIf="!!resetPasswordUser" (onClose)="onResetPasswordModalClosed()"
                          [user]="resetPasswordUser">
</app-reset-password-modal>
<app-reset-disclaimer-modal *ngIf="!!resetDisclaimerUser" (onClose)="onResetDisclaimerModalClosed()"
                            [user]="resetDisclaimerUser">
</app-reset-disclaimer-modal>
