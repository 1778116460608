<header class="navbar navbar-inverse navbar-fixed-top" role="banner">
    <a id="leftmenu-trigger" (click)="toggleLeftMenu()" class="tooltips" data-toggle="tooltip" data-placement="right" title="" data-original-title="Toggle Sidebar"></a>

    <div class="navbar-header pull-left">
        <a class="navbar-brand" routerLink="/main" href="#/dashboard">Compit BackOffice</a>
    </div>

    <ul class="nav navbar-nav pull-right toolbar">
    	<li dropdown>
    		<a class="text-clickable username dropdown-toggle" dropdownToggle><span class="hidden-xs">{{user.firstName}} {{user.lastName}} <i class="fa fa-caret-down"></i></span></a>
        <ul class="dropdown-menu userinfo arrow" *dropdownMenu>
    			<li class="userlinks">
    				<ul class="dropdown-menu">
    					<!-- <li><a href="#">Edit Profile <i class="pull-right fa fa-pencil"></i></a></li> -->
    					<!--<li><a href="#">Account <i class="pull-right fa fa-cog"></i></a></li>
    					<li><a href="#">Help <i class="pull-right fa fa-question-circle"></i></a></li>
    					<li class="divider"></li> -->
              <li><a (click)="logout()" class="text-clickable text-right">Sign Out</a></li>
    				</ul>
    			</li>
    		</ul>
    	</li>
     </ul>
</header>
<div id="page-container">
  <nav id="page-leftbar" role="navigation">
    <ul class="acc-menu" id="sidebar">
        <li><a routerLink="/main/users" href="#"><i class="fa fa-home"></i> <span>Dashboard</span></a></li>
        <li><a routerLink="/main/users" href="#"><i class="fa fa-users"></i> <span>Users Management</span> </a></li>
        <li><a routerLink="/main/leads" href="#"><i class="fa fa-user-plus"></i> <span>Leads Management</span> </a></li>
        <li><a routerLink="/main/permissions" href="#"><i class="fa fa-key"></i> <span>Permissions Management</span> </a></li>
        <li><a routerLink="/main/accounts" href="#"><i class="fa fa-briefcase"></i> <span>Account Management</span> </a></li>
        <li><a routerLink="/main/debug-info" href="#"><i class="fa fa-bug"></i> <span>Debug Info</span> </a></li>
        <li><a routerLink="/main/image-validation" href="#"> <i class="fa fa-image"></i> <span>Image Validation</span> </a></li>
    </ul>
  </nav>
  <div id="page-content">
    <div id="wrap">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
