<div class="modal-header">
  Cancel Subscription
</div>
<div class="modal-body">
  Are you sure you want to cancel the subscription?
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="onCancel()">No</button>
  <button class="btn btn-warning" (click)="cancelSubscription()">Yes</button>
</div>
