<div class="panel panel-sky">
  <div class="panel-heading">
    <h4><i class="fa fa-bug"></i> Debug Info</h4>
  </div>
  <div class="panel-body collapse in" style="display: block;">
    <div class="tab-container tab-info">
      <ul class="nav nav-tabs">
        <li [class.active]="selectedTab === 'sale'" class="active"><a class="text-clickable" (click)="selectTab('sale')">Sale</a></li>
        <li [class.active]="selectedTab === 'rent'"><a class="text-clickable" (click)="selectTab('rent')">Rent</a></li>
      </ul>
      <div class="tab-content">
        <div class="input-group well search-container">
          <input [(ngModel)]="typeaheadValue" placeholder="Search apartments..." [typeahead]="asyncResults" #typeahead [typeaheadAsync]="true"
            (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionField]="'name'" [typeaheadItemTemplate]="customItemTemplate"
            class="form-control" />
          <span class="input-group-btn">
            <button type="button" class="btn btn-primary"><i class="fa fa-search"></i> Search</button>
          </span>
        </div>
        <div class="alert alert-warning">
          <strong>Note: </strong> Debug info does not work for house selection
        </div>
      </div>
    </div>
  </div>
</div>






<ng-template #customItemTemplate let-model="item" let-index="index">
  <span [innerHtml]="model.highlighted"></span>
</ng-template>

<app-approx-modal *ngIf="!!approxRes" [approxRes]="approxRes" (onClose)="onModalClosed()"></app-approx-modal>
