<div class="user-details">
  <div class="form-box">
    <div class="form-holder" *ngIf="formReady">
      <h3 class="form-title">
        <span *ngIf="!isEdit">User Details - {{userModel.fullName}}</span>
        <span *ngIf="isEdit && isNew">Add new User</span>
        <span *ngIf="isEdit && !isNew">Edit {{userModel.fullName}}</span>
      </h3>
      <div class="form-body">
        <form *ngIf="formReady" novalidate [formGroup]="form" (ngSubmit)="onSubmit(form.value)"
              [ngClass]="{'has-error': !form.valid && isFormSubmitted}" class="form-horizontal row-border">
          <div class="panel-body collapse in">
            <div class="row">
              <div class="col-sm-8">
                <label class="control-label">Email *</label>
                <input type="email" class="form-control" name="email"
                       formControlName="email" placeholder="Email"/>
                <ul class="help-block list-unstyled parsley-error-list"
                    *ngIf="!form.controls['email'].valid && isFormSubmitted">
                  <li class="required" style="display: list-item;">Email is not valid</li>
                </ul>
              </div>
              <div class="col-sm-4">
                <label class="control-label">Account *</label>
                <select class="form-control" formControlName="accountId" name="accountId" #account
                        (change)="setNewAccountRoles(account.value)">
                  <option *ngFor="let account of accounts" [value]="account.id">
                    {{account.name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label class="control-label">First Name *</label>
                <input type="text" class="form-control" formControlName="firstName" placeholder="First Name"
                       name="firstName">
                <ul class="help-block list-unstyled parsley-error-list"
                    *ngIf="!form.controls['firstName'].valid && isFormSubmitted">
                  <li class="required" style="display: list-item;">First name is required</li>
                </ul>
              </div>
              <div class="col-sm-4">
                <label class="control-label">Last Name *</label>
                <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name"
                       name="lastName">
                <ul class="help-block list-unstyled parsley-error-list"
                    *ngIf="!form.controls['lastName'].valid && isFormSubmitted">
                  <li class="required" style="display: list-item;">Last name is required</li>
                </ul>
              </div>
              <div class="col-sm-4">
                <label class="control-label">Phone</label>
                <input type="text" class="form-control" formControlName="phone" placeholder="Phone Number"
                       name="phone">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label class="control-label">
                  Subscription Type
                  <i placement="left" [tooltip]="subscriptionTypeTooltip" class="fa fa-info-circle"></i>
                  <ng-template #subscriptionTypeTooltip>
                    Broker = apt,bld,ngh,adv<br/>
                    Developer = apt,bld,site,adv<br/>
                    internal = all
                  </ng-template>
                </label>
                <select class="form-control" name="subscriptionTypes" formControlName="subscriptionType">
                  <option *ngFor="let subscriptionType of subscriptionTypes" [value]="subscriptionType.key">
                    {{subscriptionType.value}}
                  </option>
                </select>
              </div>
              <div class="col-sm-4">
                <label class="control-label">
                  Subscription Level <i placement="left" tooltip="Normal = Allow share & print, Empty = Do not allow"
                                        class="fa fa-info-circle"></i>
                </label>
                <select class="form-control" formControlName="subscriptionLevel" name="subscriptionLevel">
                  <option *ngFor="let subscriptionLevel of subscriptionLevels" [value]="subscriptionLevel.key">
                    {{subscriptionLevel.value}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label class="control-label">Office Address</label>
                <input type="text" class="form-control" formControlName="officeAddress" placeholder="Office Address"
                       [typeahead]="distinctOfficeAddresses" typeaheadOptionsLimit="10"/>
              </div>
              <div class="col-sm-4">
                <label class="control-label">Agent Type</label>
                <input type="text" class="form-control" formControlName="agentType" placeholder="Agent Type"
                       name="agentType">
              </div>
              <div class="col-sm-4">
                <label class="control-label">Team</label>
                <input type="text" class="form-control" formControlName="team" placeholder="Team"
                       name="team">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <label class="control-label">BIO</label>
                <textarea class="form-control" formControlName="bio" placeholder="BIO" rows="5"
                          name="bio"></textarea>
              </div>
              <div class="col-sm-4" *ngIf="userModel && !userModel.empty">
                <div class="row">
                  <div class="col-sm-12">
                    <label class="control-label">Created On:</label>
                    <input disabled class="form-control" [placeholder]="userModel.createdOn | date:'medium'">
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <label class="control-label">Disclaimer Version:</label>
                    <input disabled class="form-control" [placeholder]="userModel.disclaimerVersion | date:'medium'">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <div class="row" *ngIf="isEdit">
              <div class="col-sm-3">
                <label class="control-label">Account roles</label>
                <div class="role-holder">
                  <ng-container *ngFor="let role of roles">
                    <label class="role-label" *ngIf="userModel.account.roles.indexOf(role._id) !== -1">
                      <input class="form-control" disabled [placeholder]="role.displayName"/>
                    </label>
                  </ng-container>
                </div>
              </div>
              <div class="col-sm-3 col-sm-offset-6 control-label" *ngIf="!userModel.permissions">
                <button class="btn-inverse btn" (click)="userModel.permissions = {}">
                  <i class="fa fa-key"></i> Use custom permissions
                </button>
              </div>
              <div class="col-sm-9" *ngIf="userModel.permissions && isEdit">
                <label class="col-sm-3 control-label">Custom permissions</label>
                <div class="col-sm-6">
                  <app-edit-permissions [selectedPermissions]="userModel.permissions"
                                        (permissionsChanged)="userModel.permissions = $event"></app-edit-permissions>
                </div>
                <div class="col-sm-3 control-label">
                  <button class="btn btn-danger btn-label" (click)="userModel.permissions = null">
                    <i class="fa fa-times"></i> Revoke
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="form-footer">
        <button class="btn btn-primary" (click)="onCancel()" *ngIf="!isEdit">Close</button>
        <button class="btn btn-primary" (click)="toEditMode()" *ngIf="!isEdit">Edit</button>
        <button class="btn btn-primary" (click)="toViewMode()" *ngIf="isEdit">Cancel</button>
        <button class="btn btn-primary" [disabled]="!form.valid && !form.dirty" (click)="submitForm()" *ngIf="isEdit">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
