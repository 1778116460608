<div class="text-container" *ngIf="!editMode" (click)="onSpanClicked()">
  <span>{{displayValue}}</span>
  <input class="form-control not-visible" />
</div>
<div class="input-container" *ngIf="editMode" [class.open]="displayDropdown">
  <input class="form-control" *ngIf="!values" [(ngModel)]="value" #inputEl
        (keyup.enter)="enterKeyPressed($event)" (keydown)="keyDown($event)" (blur)="onBlurEvent()" />
  <app-autocomplete #autocompleteEl *ngIf="values" (onSelect)="changeValue($event); triggerChange()" (onBlur)="onBlurEvent()"
      (keydown)="keyDown($event)" [value]="value" [values]="values"></app-autocomplete>
</div>
