<div class="panel panel-sky">
  <div class="panel-heading">
    <h4><i class="fa fa-users"></i> Bulk User Registration</h4>
  </div>
  <div class="panel-body">
    <div *ngIf="!parsingUsers && !users.length">
      <div class="fileinput fileinput-new" data-provides="fileinput">
        <span class="btn btn-default btn-file">
          <span class="fileinput-new">Select CSV File</span>
          <input type="file" name="..." accept=".csv" (change)="onCSVSelected($event)">
        </span>
        <span class="fileinput-filename"></span>
        <a href="#" class="close fileinput-exists" data-dismiss="fileinput" style="float: none">×</a>
      </div>
      <a class="btn btn-default btn-label" href="../../assets/template.csv" download target="_blank"><i class="fa fa-download"></i> Download CSV Template</a>
    </div>
    <div *ngIf="parsingUsers">
      <i class="fa fa-spinner fa-pulse fa-fw"></i> Parsing users from CSV file...
    </div>
  <div *ngIf="users.length">
      <button class="btn btn-primary btn-label" (click)="uploadUsers()"><i class="fa fa-upload"></i> Register Users</button>
      <button class="btn btn-secondary btn-label" (click)="retryClicked()"><i class="fa fa-refresh"></i> Re-Upload</button>
      <table class="editable-table table table-striped table-bordered table-hover datatables dataTable">
        <thead>
          <th colspan="2">Email</th>
          <th>FirstName</th>
          <th>LastName</th>
          <th>Phone</th>
          <th class="bio-column">Bio</th>
          <th>Address</th>
          <th>SubscriptionLevel</th>
          <th>SubscriptionType</th>
        </thead>
        <tbody>
          <tr *ngFor="let user of users; let i = index" [class.odd]="i % 2 !== 0" [class.even]="i % 2 === 0">
            <td>
              <span *ngIf="getIsValidFromServer(i) !== null" class="user-status">
                <i class="text-success fa fa-check-circle" aria-hidden="true" *ngIf="getIsValidFromServer(i) === true"></i>
                <i class="text-danger fa fa-times-circle" aria-hidden="true" *ngIf="getIsValidFromServer(i) !== true"
                  [tooltip]="getIsValidFromServer(i)" placement="left"></i>
              </span>
            </td>
            <td *ngFor="let column of userFields; let columnIndex = index" [ngClass]="column.className"
              [class.bg-danger]="isFieldInvalid({row: i, columnIndex: columnIndex})">
              <app-editable-text [editMode]="isFieldSelected({row: i, columnIndex: columnIndex})" (change)="changeUserProp(user, column.name, $event)" 
                [value]="user[column.name]" [values]="column.values" (onTab)="setNextEditingField($event)" 
                (click)="setEditingField({row: i, columnIndex: columnIndex})" (onBlur)="clearSelection($event)" 
                (moveUp)="moveEditingFieldUp()" (moveDown)="moveEditingFieldDown()" [validations]="column.validations" 
                (fieldValidationStateChange)="markFieldValidState($event, {row: i, columnIndex: columnIndex})">
              </app-editable-text>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-bulk-user-upload-confirmation-modal *ngIf="showConfirmationModal" (onClose)="closeConfirmationModal()" (apply)="registerUsers()"
  [usersCount]="users.length" [errorsCount]="usersErrorsCount">
</app-bulk-user-upload-confirmation-modal>
