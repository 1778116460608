<div class="panel panel-sky">
  <div class="panel-heading">
    <h4><i class="fa fa-key"></i> {{editMode ? 'Update': 'Create'}} Role</h4>
  </div>
  <div class="panel-body" style="display: block;">
    <div class="form-horizontal">
      <div class="form-group">
        <label class="col-xs-3 control-label">
          <span>Role name:</span>
          <input class="form-control" #roleName="ngModel" placeholder="Role name" type="text" [(ngModel)]="role.name" required />
        </label>
        <label class="col-xs-3 control-label">
          <span>Role display name:</span>
          <input class="form-control" #roleDisplayName="ngModel" placeholder="Role display name" type="text" [(ngModel)]="role.displayName" required />
        </label>
      </div>
      <div *ngIf="permissions" class="row">
        <app-edit-permissions class="col-xs-6" [selectedPermissions]="role.permissions" (permissionsChanged)="changePermissions($event)"></app-edit-permissions>
      </div>
        <div class="btn-toolbar">
          <button class="btn-primary btn" type="button" (click)="saveRole()" [disabled]="roleName.invalid || roleDisplayName.invalid">Submit</button>
          <!--<a class="btn-default btn text-clickable" (click)="cancel.emit()">Cancel</a>-->
      </div>
    </div>
  </div>
</div>
